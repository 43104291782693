@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@900&display=swap");
.header {
}

.logo-header {
}

.navbar-light .navbar-toggler-icon {
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='%23EBAD55' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e") !important;
}

.navbar-light .navbar-toggler {
	color: rgba(0, 0, 0, 0.55);
	background: #4d4d4d;
	border-color: #ebad55 !important;
}
