@import "bootstrap/dist/css/bootstrap.min.css";
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@900&display=swap");
@import "node_modules/bootstrap/scss/mixins";
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";

.logo-header {
	width: 60px;
}

.flip-container {
	-webkit-perspective: 1000;
	width: 400px;
}
.flipper {
	transition: 0.6s;
	-webkit-transform-style: preserve-3d;
	position: relative;
	height: 300px;
}

.not-flipper {
	transition: 0.6s;
	-webkit-transform-style: preserve-3d;
	position: relative;
	height: 300px;
}
.nft-image {
	border: 3px solid #f9ba55;
	transition: all 0.2s ease-in-out;
}
.nft-image:hover {
	box-shadow: 0 0 10px #9ecaed;
	transform: scale(1.05);
}
.steps {
	background: #2a2a2a;
}
.introjs-tooltip-header,
.introjs-tooltiptext,
.introjs-bullets,
.introjs-tooltipbuttons {
	background: #2a2a2a;
}
.introjs-skipbutton {
	color: white !important;
}
.introjs-skipbutton:hover {
	color: rgba(255, 255, 255, 0.514) !important;
}
.front,
.back {
	width: 400px;
	position: absolute;
	left: 0;
	top: 0;
	-webkit-backface-visibility: hidden;
	color: #fff;
	text-shadow: 1px 1px #000;
	font-size: 2em;
	line-height: 200px;
	text-align: center;
}

::-webkit-scrollbar {
	width: 1px;
}

/* Track */
::-webkit-scrollbar-track {
	background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #ebad55;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: transparent;
}

.factory {
	background-image: url("../assets/house/factory_before.png");
	width: 750px;
	height: 500px;
	background-size: contain;
	background-repeat: no-repeat;
}
.factory:hover {
	background-image: url("../assets/house/factory_hover.gif");
	width: 750px;
	height: 500px;
	background-size: contain;
	background-repeat: no-repeat;
	cursor: pointer;
}
.grow {
	transition: all 0.2s ease-in-out;
}

.grow:hover {
	transform: scale(1.1);
}
